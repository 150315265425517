import React from "react";
import MenuItem from "../MenuItem/MenuItem";

const MenuCategory = ({ title, data }) => {
  return (
    <div className="category">
      <div className="category-heading">{title}</div>

      {data.map((item, i) => (
        <MenuItem
          key={i}
          title={item.title}
          discription={item.discription}
          price={item.price.toString()}
          signature={item.signature}
        />
      ))}
    </div>
  );
};

export default MenuCategory;
