import React from "react";
import { menuItemsList } from "../../db/menuItemsList";
import MenuCategory from "../MenuCategory/MenuCategory";

const Menu = () => {
  const banners = ["/images/cyo.png", "/images/fl.png", "/images/mm.png"];
  return (
    <div style={{ maxWidth: "500px" }}>
      <div className="title">Menu</div>
      {menuItemsList.map((item, i) => (
        <>
          <MenuCategory key={i} title={item.title} data={item.data} />
          {banners.length > i && (
            <img src={banners[i]} alt="Uncle M" style={{ width: "100%" }} />
          )}
        </>
      ))}
    </div>
  );
};

export default Menu;
