import React from "react";

const Footer = () => {
  return (
    <footer>
      Copyright © 2023 Uncle M. All rights reserved. <br />
      made by &nbsp;
      <a
        href="https://www.sajeelaalam.co.uk"
        target="_blank"
        without="true"
        rel="noreferrer"
      >
        Sajeel Aalam
      </a>
    </footer>
  );
};

export default Footer;
