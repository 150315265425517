import "./App.css";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Menu from "./components/Menu/Menu";
import { useState } from "react";

function App() {
  const [showMenu, setShowMenu] = useState(false);
  return (
    <div className="App">
      {!showMenu ? (
        <div className="main-screen">
          <div
            className="triangle-logo"
            id="triangle-topleft"
            onClick={() => setShowMenu(true)}
          >
            <img
              className="app-logo"
              src="/logo-192.png"
              alt="Uncle M"
              style={{ height: "170px" }}
            />
          </div>
          <div className="triangle-logo" id="triangle-bottomright">
            <img
              className="app-logo"
              src="/wts-logo-192.png"
              alt="Whats the SCOOP"
              style={{ height: "170px" }}
            />
            <br />
            <br />
            coming soon!
          </div>
        </div>
      ) : (
        <>
          <Header />
          <Menu />
          <Footer />
          <div
            style={{ margin: "20px 20px 50px 20px" }}
            onClick={() => setShowMenu(false)}
          >
            <img
              className="app-logo"
              src="/wts-logo-192.png"
              alt="Whats the SCOOP"
              style={{ height: "120px" }}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default App;
