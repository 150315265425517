import React, { useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { GrClose } from "react-icons/gr";
import { FaLocationArrow } from "react-icons/fa";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };

  return (
    <header className={isOpen ? "full" : ""}>
      <div className="main-nav">
        <div onClick={() => setIsOpen(!isOpen)}>
          {isOpen ? <GrClose /> : <GiHamburgerMenu />}
        </div>
        <img
          className="app-logo"
          src="/logo-192.png"
          alt="Uncle M"
          style={{ height: "100px" }}
        />
        <div
          onClick={() => {
            openInNewTab("https://goo.gl/maps/3FfZoLHLcC8Ry38e9");
            setIsOpen(!isOpen);
          }}
        >
          <FaLocationArrow />
        </div>
      </div>

      {isOpen && (
        <>
          <nav>
            <li onClick={() => setIsOpen(!isOpen)}>Menu</li>
            <li>Create Your Own</li>
            <li
              onClick={() => {
                openInNewTab("https://goo.gl/maps/3FfZoLHLcC8Ry38e9");
                setIsOpen(!isOpen);
              }}
            >
              Finds US
            </li>
            <li>About US</li>
            <li>Contact US</li>
            <br />
            <br />
          </nav>
          <div className="feature-nav">
            <img
              id={isOpen ? "showMe" : ""}
              src="images/featured.png"
              style={{ borderRadius: "1rem" }}
              alt="featured"
            />
          </div>
        </>
      )}
      {/* {isOpen && <div>Hello World</div>} */}
    </header>
  );
};

export default Header;
