export const menuItemsList = [
  {
    title: `SUB 6"`,
    data: [
      {
        title: "The Mexican",
        discription: `Beef Pepperoni, Chicken Tikka, Double Mozzarella, Crushed Doritos, Sweetcorn, Peppers, Chipotle and Hot Sauce`,
        price: "£3.49",
      },
      {
        title: "B.L.T",
        discription: `Turkey Bacon, Lettuce, Tomatoes, Cucumbers, Red Onions, Chipotle`,
        price: 3.49,
      },
      {
        title: "The American",
        discription: `Kebab, Mixed Peppers, Red Onions, Top with Ketchup or Burger Sauce`,
        price: 3.49,
      },
      {
        title: "The Classic",
        discription: `Chicken Tandoori, Double American Cheese, Lettuce, Cucumbers, Tomatoes, Red Onions, Mayo and Burger Sauce`,
        price: 3.49,
      },
      {
        title: "Tuna Twist",
        discription: `Tuna Mayonnaise, Mozzarella, Red onions, Peppers, Black Pepper and Sweet Chilli Sauce`,
        price: 3.49,
      },
      {
        title: "The Cheesy One",
        discription: `Mix of our in house cheeses for those big cheese pulls`,
        price: 3.49,
      },
      {
        title: "Vegi Sizzler",
        discription: `Lettuce, Tomatoes, Green Peppers, Red Onions, 
        Gherkins, Jalapeños and Top with Chilli Sauce`,
        price: 3.49,
      },
      {
        title: "Signature",
        discription: `Turkey Bacon, Chicken tikka cheese slices Onions Mixed Peppers Gherkins Cucumber Lettuce Sweetcorn Chipotle sauce and Ranch`,
        price: 4.49,
        signature: true,
      },
    ],
  },
  {
    title: `SIDES`,
    data: [
      {
        title: "Wedges",
        discription: `Seasoned Baked Potato Wedges are the perfect side dish`,
        price: 2.99,
      },
      {
        title: "Firey Wedges",
        discription: `Seasoned with our special spice mix, for the extra kick`,
        price: 3.99,
      },
      {
        title: "The Slaw",
        discription: `Cool Off with our finely sliced Coleslaw`,
        price: 1.99,
      },
      {
        title: "The Mac",
        discription: `Nothing but Creamyyy Mac n Cheese here`,
        price: 3.99,
      },
    ],
  },
  {
    title: `SUPER SIDES`,
    data: [
      {
        title: "Jacket Potato",
        discription: `Mozzarella Cheese, Beans and Choice of Meat or Veg`,
        price: 3.99,
      },
      {
        title: "Pasty Bakes",
        discription: `Choose From <br/> &nbsp;&nbsp;- Cheese & Onion <br/> &nbsp;&nbsp;- Minced Meat`,
        price: 1.99,
      },
    ],
  },
  {
    title: `ALERGY ADVICE`,
    data: [
      {
        title: "Please",
        discription: `For more information about food allergies and intolerances, Please ask a member of staff.`,
        price: "!",
        signature: true,
      },
    ],
  },
];
