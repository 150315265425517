import React from "react";
import parse from "html-react-parser";

const MenuItem = ({ title, discription, price, signature }) => {
  return (
    <div className={signature ? "menuItem signature" : "menuItem"}>
      <div className="menuItem-details">
        <div className="menuItem-title">{title}</div>
        <div className="menuItem-discription">{parse(discription)}</div>
      </div>
      <div className="menuItem-price">
        {price === Number ? "£" + price : price}
      </div>
    </div>
  );
};

export default MenuItem;
